import React, { useState } from "react";
import logo from "./massa-logo.webp";
import "./App.css";

function App() {
  const [value, setValue] = useState<string>("");
  const [nodeReward, setNodeReward] = useState<string>("0");
  const [questReward, setQuestReward] = useState<string>("0");
  const [isRolls, setRolls] = useState<boolean>(false);




  const generateReward = () => {
    //setNodeReward();
    //setQuestReward();
    //setRolls();
    fetch(
      `https://massa-api.pgrok.io/addresses?id=${value}`
    )
    .then((response) => response.json())
    .then(
      item => {
        if(item.length > 0) {
          setNodeReward(item[0].node_running_coins)
          setQuestReward(item[0].quest_coins)
  
          if(item[0].wants_initial_rolls) {
            setRolls(true)
          } else {
            setRolls(false)
          }
        }else {
          setNodeReward('0')
          setQuestReward('0')
          setRolls(false)
        }

      }
    )
    console.log(value)
  };

  return (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <div className="times-main-container">
        <div>
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type="text"
          />
        </div>
        <button className="button-1" onClick={generateReward}>
          Hesapla
        </button>
      </div>

      <div className="glass-main-container">
        <div className="glass-card">
          <div className="glass-card-label">Node Çalıştırma Ödülü</div>
          <div className="glass-card-description">{`${(Number(nodeReward)).toFixed(2)} MASSA`}</div>
          <div className="glass-card-label">(%30 Kilitsiz) </div>
          <div className="glass-card-description">{`${(Number(nodeReward) * 0.3).toFixed(2)} MASSA`}</div>
          <div className="glass-card-label">(%70 Kilitli 24 Ay Vesting) </div>
          <div className="glass-card-description">{`${(Number(nodeReward) * 0.7).toFixed(2)} MASSA`}</div>
          <div className="glass-card-label">Quest Ödülü</div>
          <div className="glass-card-description">{`${Number(questReward).toFixed(2)} MASSA`}</div>
          <div className="glass-card-label">
            Genesiste Validatör olmayı Seçiyorum
          </div>
          <div className="glass-card-description">
            {isRolls ? "Evet" : "Hayır"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
